<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 信用卡首页
 * @Date: 2020-11-17 21:12:38
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-20 16:55:58
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/creditCard/Credit.vue
-->
<template>
  <div>
    <router-view />
    <!-- <van-tabbar route v-model="active">
      <van-tabbar-item icon="card" to="/Credit/creditcardapply">申请信用卡</van-tabbar-item>
      <van-tabbar-item icon="column" to="/Credit/ApplicationRecord">申请记录</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
import Vue from 'vue'
import { Tabbar, TabbarItem } from 'vant'

Vue.use(Tabbar)
Vue.use(TabbarItem)
export default {
  data() {
    return {
      active: 0
    }
  }
}
</script>

<style></style>
